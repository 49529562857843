<template>
  <div class="Login_root">
    <v-form ref="form">
      <TextFieldInput
        :value="loginForm.email"
        placeholderValue="random@gmail.com"
        name="email"
        :rules="[...inputRules.required, ...inputRules.email]"
        label="email address"
        hasShadow
        @input="handleInput(arguments[0], 'email')"
        removeSpacesHandler
      />
      <TextFieldInput
        :value="loginForm.password"
        placeholderValue="************"
        type="password"
        name="password"
        label="password"
        :rules="[...inputRules.required]"
        hasShadow
        :passwordInput="true"
        @input="handleInput(arguments[0], 'password')"
        removeSpacesHandler
      />
      <div class="Login_links">
        <div>Forgot password ?
          <router-link :to="{ name: 'reset' }">Reset password</router-link>
        </div>
        <div>No Account ?
          <router-link :to="{ name: 'registration' }">Signup</router-link>
        </div>
      </div>
      <Button
        size="large"
        text="Login"
        @click="handleLogin"
        defaultBtn
        fullWidth
        brightBtn
      ></Button>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import inputRules from '@/utils/inputRules';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Button from '@/components/_common/FormElements/Button/Button';
import { validateForm } from '@/mixins/validateForm';

export default {
  components: {
    TextFieldInput,
    Button,
  },
  mixins: [ validateForm ],
  data: () => ({
    inputRules,
    loginForm: {
      email: '',
      password: '',
    },
  }),
  methods: {
    ...mapActions('user', [ 'apiSigninAction', 'verifyUserEmail' ]),
    ...mapActions('invoicing', [ 'updateUserInvoicePayemntStatus' ]),
    handleInput(value, field) {
      this.loginForm[ field ] = value;
    },
    handleLogin() {
      this.validateForm().then(() => {
        this.apiSigninAction(this.loginForm)
          .then(res => {
            const { data: { token, is_2fa_enabled }} = res;
            if (is_2fa_enabled) {
              this.$router.push({
                name: 'login.verify',
                query: this.$route.query.invoice ? { invoice: this.$route.query.invoice } : this.$route.query.zendesk_auth ? { zendesk_auth: 'true' } : '',
              });
            } else {
              if (token)
                this.$router.push({
                  name: this.$route.query.invoice ? 'invoicing.pay' : this.$route.query.zendesk_auth ? 'zendesk' : 'exchange.dashboard',
                  params: this.$route.query.invoice ? { invoice: this.$route.query.invoice } : '',
                  query: this.$route.query.zendesk_auth ? { brand_id: 'true' } : '',
                });
              this.updateUserInvoicePayemntStatus(this.$route.query.invoice ? true : false);
            }
          })
          .catch(error => {
            if (error === 'goToResend') this.$router.push({
              name: 'login.resend',
              params: { email: this.loginForm.email }
            });
          });
      })
    },
    verifyUserAccount() {
      if (this.$route.query && this.$route.query.verify_token) {
        this.verifyUserEmail(this.$route.query.verify_token);
      }
    },
  },
  mounted() {
    this.verifyUserAccount();
  },
};
</script>

<style lang="scss">
.Login_root {
  max-width: 400px;
  margin: 0 auto;

  input {
    font-size: 16px !important;
  }
}

.Login_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin: 0 0 25px;

  div {
    flex: 0 1 auto;

    a {
      color: #2af3f3;
      text-decoration: none;
    }
  }
}
</style>
